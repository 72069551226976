import * as React from 'react';
import { Popup } from '@archinsurance-viki/property-jslib';

import { PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { SUPPLEMENTAL_DEFINITIONS } from '../../../../constants/Submission';
import { INFO_PANEL_TYPES, SUBMISSION_INFOBLOCKS } from '../../../../constants/SubmissionInfoblocks';
import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import { processColumnsForInfoBlocks } from '@archinsurance-viki/property-jslib/src/utils/tables/columns';
import MapInfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/MapInfoBlock';
import { submissionIsEditable } from '../../../submissionlog/Helpers';
import { AppContextType } from '../../../../ts-types/AppTypes';
import { Types } from '../../../../ts-types/viki-types';
import { RouterContext } from '@archinsurance-viki/property-jslib/src/utils/context';

type propTypes = {
    CONSTANTS: Types.Constants;
    tableData: PagedTableDataType;
    tableGlossary: Record<string, any>;
    appContext: AppContextType;
    onSaveFn: (...params: any) => any;
};

const INFO_BLOCKS = [
    SUBMISSION_INFOBLOCKS.general,
    SUBMISSION_INFOBLOCKS.business_info,
    SUBMISSION_INFOBLOCKS.broker,
    SUBMISSION_INFOBLOCKS.limit,
    SUBMISSION_INFOBLOCKS.premium,
    SUBMISSION_INFOBLOCKS.property,
    SUBMISSION_INFOBLOCKS.carrier_information,
    SUBMISSION_INFOBLOCKS.dist_by_construction,
    SUBMISSION_INFOBLOCKS.dist_by_occupancy,
];

export default class SubLogBottomPanelApp extends React.Component<propTypes> {
    static contextType = RouterContext;

    onMarkerClick = (locationId: number) => {
        Popup.plugins().popover(
            {
                title: `Building ${locationId}`,
                className: 'popup-button',
                buttons: {
                    left: [
                        {
                            text: 'Go to building?',
                            className: 'red',
                            action: () => {
                                let { history, location } = this.context;
                                let { appContext } = this.props;
                                let { currentSubmissionId } = appContext;
                                // TODO: we should enforce /submissions path to either always have trailing slash or never have it
                                const baseUrl = location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`;
                                history.push(`${baseUrl}${currentSubmissionId}/current/editor/${locationId}`);
                                Popup.close();
                            },
                        },
                    ],
                },
            },
            document.getElementById(`marker-location-${locationId}`)
        );
    };

    renderBlocks() {
        let { tableGlossary, appContext, CONSTANTS, onSaveFn } = this.props;
        let { currentSubmission } = appContext;
        let defs = processColumnsForInfoBlocks(SUPPLEMENTAL_DEFINITIONS, tableGlossary, CONSTANTS);

        return INFO_BLOCKS.map((block: any, idx: any) => {
            return (
                <InfoBlock
                    {...block}
                    locationContext={INFO_PANEL_TYPES.SUBMISSION}
                    key={idx}
                    readonly={!submissionIsEditable(currentSubmission) || block.readonly}
                    glossary={defs}
                    parentProps={{ ...appContext }}
                    helperFunctions={{
                        handleSave: onSaveFn,
                    }}
                />
            );
        });
    }

    render() {
        let { appContext, onSaveFn } = this.props;
        let { currentSubmission } = appContext;
        if (!currentSubmission) {
            return null;
        }

        const { insured_name, insured_city, insured_state, insured_zipcode } = currentSubmission;
        if (!insured_name) {
            return null;
        }
        const header = `${insured_name} - ${insured_city}, ${insured_state} ${insured_zipcode}`;
        const vexcelEndpoint = `${window.location.origin}/api/v1/fetch_vexcel_tile`;
        return (
            <React.Fragment>
                <div className="abs top-left flex handles">
                    <div className="panel-label handle">
                        <div className="text">{header}</div>
                    </div>
                </div>
                <div className="panel-area">
                    <div className="grid-layout details-panel">{this.renderBlocks()}</div>
                </div>
                <div className="panel-fixed">
                    <MapInfoBlock
                        vexcelEndpoint={vexcelEndpoint}
                        zoom={6}
                        locations={currentSubmission.buildings}
                        readonly={onSaveFn && !submissionIsEditable(currentSubmission)}
                        onMarkerClick={this.onMarkerClick}
                    />
                </div>
            </React.Fragment>
        );
    }
}
