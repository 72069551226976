import _ from 'lodash';
import { getArchLinkSubmissionUrl, getArchPWSubmissionUrl, getArchSubmissionId, getUrlFor } from '../utils/navurl-helpers';
import { getExtraDocumentProps } from '@archinsurance-viki/property-jslib/src/utils/documents-helpers';
import { calculateBCDisplayVal, calculateBCFieldFormat, getQuoteConditionLabel } from '../components/underwriting/Helpers';
import { NAVIGATION_LOCATIONS } from './Navigation';
import { ALL_COLUMNS_LIST as PAGED_TABLES_ALL_COLUMNS_LIST } from './PagedTableViewSettings';
import { ColumnType, ExtraDataType, TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { RAE_SITE_ID } from './SiteConstants';
import { convertDecimalToPercent } from '@archinsurance-viki/property-jslib/src/utils/converters';

const DEFAULT_QC_COL_WIDTH = 200;
const ID_COL_PROPS = {
    w: 60,
    field_name: 'id',
    name: 'ID',
    readonly: true,
    type: 'number',
    field_format: { nocommas: true },
};

const BC_DOLLAR_PCT_NET_GROSS_FIELD: ColumnType = {
    fieldFormatGetter: calculateBCFieldFormat,
    cellDataGetter: calculateBCDisplayVal,
    w: 100,
};

const extraDocumentRowActionIsHidden = (document: Record<string, any>): boolean => {
    if (!document || document.missing || document.av_status !== 'C') return true;
    if (['DATA_ENTRY_XLSX', 'SOV', 'SOV_BOT_DATA_ENTRY_XLSX'].includes(document.document_type)) return false;
    return true;
};

const extraDocumentRowActionText = (document: Record<string, any>): string => {
    if (extraDocumentRowActionIsHidden(document)) return 'hidden';

    if (document.document_type === 'DATA_ENTRY_XLSX') return 'Parse';
    if (document.document_type === 'SOV_BOT_DATA_ENTRY_XLSX') return 'Export';
    if (document.document_type === 'SOV') return 'Parse';

    return 'Unknown!!!';
};

const getBorStatusCellData = (data: Record<string, any>, rowData: Record<string, any>): string => {
    let pendingBorDisplay = '';
    if (rowData.pending_bor_status && rowData.pending_bor_status.length > 0) {
        const isPendingBOR = rowData.pending_bor_status === 'P';

        if (rowData.bor_incumbent_submission_id === rowData.clearance_submission_id) {
            pendingBorDisplay = ' Incumbent';
        }

        if (isPendingBOR) {
            pendingBorDisplay += ' Pending BOR';
        } else if (rowData.pending_bor_status === 'L') {
            pendingBorDisplay += ' BOR Received';
        }
    }
    return pendingBorDisplay;
};

const extraDocumentRowActionHint = (document: Record<string, any>): string => {
    if (extraDocumentRowActionIsHidden(document)) return 'hidden';

    if (document.document_type === 'DATA_ENTRY_XLSX') return 'Update submission with values from this Data Entry Spreadsheet';
    if (document.document_type === 'SOV_BOT_DATA_ENTRY_XLSX') return 'Writes the submission data to an Excel file';
    if (document.document_type === 'SOV') return 'Rewrite incoming client SOV as VIKI Data Entry Spreadsheet that can be imported into VIKI';

    return 'Unknown!!!';
};

// Use this method as the readonly property for any `accounttransactiondatasqlview` field that is editable even in the
// case where the submission is non-premium bearing.
export const editableEvenIfNotPremiumBearingReadonly = (rowData: Record<string, any>): boolean => {
    if (!rowData) {
        return true;
    }
    return !rowData.is_editable;
};

// Use this method as the readonly property for any `buildingdatasqlview` field that is editable even in the
// case where the submission is non-premium bearing.
export const editableBuildingEvenIfNotPremiumBearingReadonly = (rowData: Record<string, any>, extraData: Record<string, any>): boolean => {
    if (!extraData) {
        return true;
    }
    return !extraData.isSubmissionEditable;
};

export const editableIfUseAssignedClaimsAdjusterSelected = (rowData: Record<string, any>): boolean => {
    return !rowData.use_assigned_claims_adjuster;
};

export const editableIfSubmissionFromRaeInbox = (rowData: Record<string, any>): boolean => {
    return !(rowData.submission_source === 'VI' && rowData.site_id === RAE_SITE_ID);
};

const editableIfSubmissionIsBound = (rowData: Record<string, any>) => rowData.status === 'BOUND';

const getArchSubmissionIDCellData = (
    data: { arch_policywriter_mc_id: number; arch_policywriter_ct_id: number; arch_policywriter_version_no: number },
    rowData: { arch_submission_id: string }
): string => {
    let archSubmissionId = '';

    if (data && rowData?.arch_submission_id && rowData?.arch_submission_id.length > 0) {
        archSubmissionId = rowData?.arch_submission_id;
        archSubmissionId = getArchSubmissionId(archSubmissionId);
    }

    return archSubmissionId;
};

const accounttransactiondatasqlview: TableGlossaryType = {
    i: ID_COL_PROPS,
    rroi: {
        urlFn: (data: Record<string, any>, extraData) => {
            return { target: '_blank', url: getArchLinkSubmissionUrl(extraData.env, data.rae_renewal_of_id) };
        },
    },
    aid: { ...ID_COL_PROPS, field_name: 'account_id' },
    woi: { field_format: { nocommas: true } },
    csi: { type: 'number', field_format: { nocommas: true }, field_name: 'clearance_submission_id' },
    acaid: { w: 110, readonly: editableIfSubmissionFromRaeInbox },
    asaid: { w: 120, readonly: editableIfSubmissionFromRaeInbox },
    arcsd: { w: 95 },
    arcbd: { w: 135 },
    archpf: { w: 135 },
    apmi: {
        cellType: 'text',
        cellDataGetter: getArchSubmissionIDCellData,
        urlFn: (data: { arch_policywriter_mc_id: number; arch_policywriter_ct_id?: number; arch_policywriter_version_no?: number }, extraData) => {
            return {
                target: '_blank',
                url: getArchPWSubmissionUrl(
                    extraData.env,
                    data?.arch_policywriter_mc_id,
                    extraData.me.username,
                    data?.arch_policywriter_ct_id,
                    data?.arch_policywriter_version_no
                ),
            };
        },
    },
    archdoa: { w: 120 },
    archsc: { w: 125 },
    arcp: { w: 90 },
    arcr: { w: 90 },
    bs: {
        cellDataGetter: (data: string, rowData: Record<string, any>, label, extraData: ExtraDataType): string => {
            const OLD_BUSINESS_SEGMENT_VALUES = {
                ES_LINES: 'E&S Lines',
                SMMS: 'SMMS',
                RETAIL_PROPERTY: 'Retail Property',
            };
            const NEW_BUSINESS_SEGMENT_VALUES = {
                ES_LINES: 'E&S Lines',
                SMMS: 'Middle Market Solutions',
                RETAIL_PROPERTY: 'Middle Market Property',
            };
            const policyInceptionDate = new Date(rowData.policy_inception_date);
            const Pruw9181ReleaseDate = new Date('2024-04-20');
            if (extraData.siteId === RAE_SITE_ID && (!rowData.policy_inception_date || policyInceptionDate >= Pruw9181ReleaseDate)) {
                return NEW_BUSINESS_SEGMENT_VALUES[data];
            } else {
                return OLD_BUSINESS_SEGMENT_VALUES[data];
            }
        },
    },
    nor: { w: 90 },
    roi: { w: 100, field_format: { nocommas: true } },
    roppn: { w: 115 },
    rbi: { w: 100, name: 'Next SID', field_format: { nocommas: true } },
    rbppn: { w: 120, name: 'Next Policy' },
    tqd: { w: 105 },
    pqtapao: { w: 120 },
    pqtcpao: { w: 120 },
    pqtpao: { w: 120 },
    tp: { w: 120 },
    wp: { w: 120 },
    pqtbt: { w: 120 },
    ted: { w: 120 },
    dedd: { w: 105 },
    scn: { w: 120 },
    pn: { w: 120 },
    sn: { w: 120 },
    pid: { w: 120 },
    pisd: { readonly: true },
    pqapomp: { w: 120 },
    pqrpomp: { w: 120 },
    pqcr: {
        w: 75,
        cellDataGetter: (data?: string): string | number => {
            return data ? parseFloat(data).toFixed(2) : '';
        },
        cellType: 'percentage',
    },
    dc: { w: 150 },
    dr: { w: 150 },
    db: { w: 120 },
    pqi: { type: 'number', field_format: { nocommas: true }, w: 50, field_name: 'primary_quote_id' },
    pi: { w: 150 },
    lai: { w: 150 },
    aai: { w: 150 },
    iep: { readonly: editableEvenIfNotPremiumBearingReadonly && !editableIfSubmissionIsBound },
    iec: { readonly: editableEvenIfNotPremiumBearingReadonly && !editableIfSubmissionIsBound },
    ilry: { readonly: editableEvenIfNotPremiumBearingReadonly },
    ilh: { readonly: editableEvenIfNotPremiumBearingReadonly },
    abi: {
        readonly: editableEvenIfNotPremiumBearingReadonly,
        constantsFilter: (choices: [string, string, boolean, Array<number>, boolean][]) => {
            return choices.filter(p => !p[2]); // filter for not inactive choices
        },
    },
    lbi: {
        readonly: editableEvenIfNotPremiumBearingReadonly,
        constantsFilter: (choices: [string, string, boolean, Array<number>, boolean][]) => {
            return choices.filter(p => !p[2]); // filter for not inactive choices
        },
    },
    sci: { readonly: editableEvenIfNotPremiumBearingReadonly },

    in: { w: 150, readonly: editableEvenIfNotPremiumBearingReadonly },
    istr: { readonly: editableEvenIfNotPremiumBearingReadonly },
    ic: { readonly: editableEvenIfNotPremiumBearingReadonly },
    ista: { readonly: editableEvenIfNotPremiumBearingReadonly },
    iz: { readonly: editableEvenIfNotPremiumBearingReadonly },
    if: { readonly: editableEvenIfNotPremiumBearingReadonly },
    ibf: { readonly: editableEvenIfNotPremiumBearingReadonly },
    idopo: { readonly: editableEvenIfNotPremiumBearingReadonly },
    pbs: {
        w: 200,
        readonly: true,
        cellType: 'text',
        name: 'BOR Status',
        field_name: 'pending_bor_status',
        cellDataGetter: getBorStatusCellData,
    },
    pbsid: {
        w: 120,
        type: 'text',
        readonly: true,
        field_name: 'bor_incumbent_submission_id',
    },
    icn: { readonly: editableEvenIfNotPremiumBearingReadonly },
    ice: { readonly: editableEvenIfNotPremiumBearingReadonly },
    icp: { readonly: editableEvenIfNotPremiumBearingReadonly },
    rv: {
        sort: d => {
            return [...d].sort((a, b) => {
                if (a[0] > b[0]) return -1;
                if (a[0] < b[0]) return 1;
                return 0;
            });
        },
    },
    bp: { rating: true },
    dk: {
        sort_field: 'original_provisional_date_booked',
        field_name: 'original_provisional_date_booked',
        classNameFn: (rowData: Record<string, any>): string =>
            rowData && !rowData.original_date_booked && rowData.original_provisional_date_booked ? 'pdk' : '',
    },
    ofac: {
        name: 'OFAC Status',
        field_name: 'ofac_status',
        w: 75,
        readonly: true,
    },
    ofaca: {
        name: 'OFAC Approver',
        field_name: 'ofac_approver',
        w: 75,
        readonly: true,
    },
    lbai: {
        name: 'Licensed Broker Arch ID',
        field_format: { nocommas: true },
        w: 120,
    },
    pcai: {
        name: 'Producing Company Arch ID',
        field_format: { nocommas: true },
        w: 120,
    },
    uaca: {
        name: 'Use Assigned Adjuster',
    },
    acan: {
        name: 'Adjuster Name',
        readonly: editableIfUseAssignedClaimsAdjusterSelected,
    },
    acat: {
        name: 'Adjuster Title',
        readonly: editableIfUseAssignedClaimsAdjusterSelected,
    },
    acacn: {
        name: 'Adjuster Company',
        readonly: editableIfUseAssignedClaimsAdjusterSelected,
    },
    acap: {
        name: 'Adjuster Phone',
        readonly: editableIfUseAssignedClaimsAdjusterSelected,
    },
    acae: {
        name: 'Adjuster Email',
        readonly: editableIfUseAssignedClaimsAdjusterSelected,
    },
    ui: {
        readonly: editableEvenIfNotPremiumBearingReadonly,
    },
    irs: { w: 120 },
    pqisi: {
        cellDataGetter: (data: number): string | number => {
            return data ? convertDecimalToPercent(data) : data;
        },
    },
    pqdci: {
        cellDataGetter: (data: number): string | number => {
            return data ? convertDecimalToPercent(data) : data;
        },
    },
    pqiaqi: {
        cellDataGetter: (data: number): string | number => {
            return data ? convertDecimalToPercent(data) : data;
        },
    },
};
const buildingcoveragegridsqlview: TableGlossaryType = {
    i: ID_COL_PROPS,
    bl: { w: 160 },
    blcv: { w: 160 },
    blc: { w: 160 },
    cl: { w: 160 },
    clcv: { w: 160 },
    clc: { w: 160 },
    pa: { w: 350 },
    loc: { w: 75 },
    cwd: { w: 350 },
    cwad: { w: 350 },
    olalubl: { w: 350 },
    iolbli: { w: 160 },
    olblf: { w: 160 },
    olblpa: { w: 160 },
    olblda: { w: 160 },
    iolcli: { w: 160 },
    olclf: { w: 160 },
    olclpa: { w: 160 },
    olclda: { w: 160 },
    iolbbli: { w: 260 },
    olbblf: { w: 160 },
    olbblpa: { w: 160 },
    olbblda: { w: 160 },
    iipor: { w: 350 },
    ad: { w: 160 },
    bi_limit: { w: 160 },
    bt: { w: 160 },
    biiri: { w: 160 },
    biiee: { w: 160 },
    bml: { w: 160 },
    bid: { w: 160 },
    sdop: { w: 350 },
    scv: { w: 350 },
    sot: { w: 350 },
    sboc: { w: 350 },
};
const buildingdatasqlview: TableGlossaryType = {
    i: ID_COL_PROPS,
    d: { w: 160, readonly: editableBuildingEvenIfNotPremiumBearingReadonly },
    s: { w: 160 },
    brka: { w: 160 },
    c: { w: 100 },
    st: { w: 90 },
    cnty: { w: 130 },
    fips: { w: 65 },
    cntry: { w: 100 },
    es: { w: 116 },
    im: { w: 92 },
    nos: { w: 120 },
    dats: { w: 83 },
    ct: { w: 214 },
    casc: { w: 130 },
    cafu: { w: 130 },
    cafv: { w: 130 },
    cad: { w: 90 },
    cacs: { w: 136 },
    cawa: { field_format: { subtype: 'number', decimals: 2 }, w: 100 },
    cawc: { field_format: { subtype: 'number', decimals: 2 }, w: 100 },
    cari: {
        w: 82,
        cellDataGetter: (value: string): string => {
            return value ? 'Browse' : null;
        },
        urlFn: (data: Record<string, any>) => {
            return { target: '_blank', url: data.cape_analytics_cml_roof_condition_image_url };
        },
    },
    evs: { w: 86 },
    eva: { w: 125 },
    evacpsf: { w: 100 },
    vov: { w: 125 },
    vovcpsf: { w: 100 },
    aci: { w: 180 },
    act: { w: 226 },
    acad: { w: 160 },
    aomc: { w: 110, field_format: { nocommas: true } },
    acmc: {
        w: 130,
        type: 'string',
        cellDataGetter: (value: number): string => {
            return value ? 'MSB ' + value : 'N/A';
        },
    },
    bc: { w: 140 },
    efd: { w: 90 },
    ffz: { w: 80 },
    ffh: { w: 136 },
    frs: { w: 80 },
    fc: { w: 149 },
    gp: { w: 139 },
    gt: { w: 122 },
    htrc: { w: 145 },
    isr: { w: 122 },
    nosto: { w: 85 },
    o: { w: 95 },
    ogi: { w: 201 },
    ogvc: { w: 65 },
    ogg: { w: 101 },
    pc: { w: 80 },
    po: { w: 97 },
    ps: { w: 120 },
    p: { w: 65 },
    apc: {
        w: 100,
        sort: d => {
            return [...d].sort((a, b) => {
                if (parseInt(a.toString()) > parseInt(b.toString())) return 1;
                if (parseInt(a.toString()) < parseInt(b.toString())) return -1;
                return 0;
            });
        },
    },
    ra: { w: 124 },
    rc: { w: 182 },
    rca: { w: 140 },
    rd: { w: 205 },
    rda: { w: 209 },
    rp: { w: 164 },
    ryb: { w: 105 },
    bry: { w: 105 },
    byb: { w: 105 },
    sd: { w: 75 },
    soa: { w: 138 },
    sep: { w: 122 },
    abie: { w: 110 },
    sv: { w: 125 },
    svcpsf: { w: 85 },
    sp: { w: 163 },
    sf: { w: 90 },
    ur: { w: 115 },
    ws: { w: 148 },
    wt: { w: 179 },
    wp: { w: 142 },
    yb: { w: 64 },
    ygr: { w: 88 },
    grh: { w: 100 },
    gra: { w: 100 },
    z: { w: 70 },
    gcad: { w: 150, field_name: 'geocoded_street' },
    gcc: { w: 90, field_name: 'geocoded_city' },
    gcst: { w: 70, field_name: 'geocoded_state' },
    gcz: { w: 80, field_name: 'geocoded_zipcode' },
    cv: { w: 75 },
    cs: { w: 75 },
    dtcv: { w: 100 },
    dtcu: { w: 115 },
    dtcwv: {
        w: 120,
        type: 'string',
        cellDataGetter: (value: number): string => {
            return value ? value + ' mi' : '';
        },
    },
    imbfe: { w: 106 },
    iiiq: { w: 115 },
    gcs: { w: 100 },
    imdiw: { w: 125 },
    imelv: { w: 98 },
    imehp: { w: 120 },
    imelp: { w: 120 },
    imffz: { w: 110 },
    imfsd: { w: 140 },
    imfsr: { w: 140 },
    imgcq: { w: 125 },
    imaiw: { w: 128 },
    immsc: { w: 130 },
    imod: { w: 100 },
    imrca: { w: 103 },
    imrhp: { w: 130 },
    imrlp: { w: 130 },
    imsfh: { w: 125 },
    imurl: {
        w: 150,
        cellDataGetter: (value: string): string => {
            return value ? 'Browse' : null;
        },
        urlFn: (data: Record<string, any>) => {
            return { target: '_blank', url: data.imap_visualization_url };
        },
    },
    imwfd: { w: 140 },
    imwfr: { w: 140 },
    imst: { w: 110 },
};
const backgroundtask: TableGlossaryType = {
    i: {
        w: 90,
        field_name: 'id',
        name: 'Task ID',
        readonly: true,
        type: 'number',
        field_format: { nocommas: true },
    },
    sub: {
        w: 80,
        field_format: { nocommas: true },
        sort_field: 'submission_id',
        urlFn: (data: Record<string, any>) => {
            let urlParams = NAVIGATION_LOCATIONS.OVERVIEW;
            let urlData = { ...data };
            // handle endorsement package links
            if (data.task_type === 'ENDORSEMENT_PACKAGE') {
                urlParams = NAVIGATION_LOCATIONS.TRANSACTION;
                urlData.transaction_id = data.submission_id;
            }
            return { target: null, url: getUrlFor(urlParams, urlData) };
        },
    },
    s: { readonly: true },
    sfn: {
        name: 'User',
        w: 120,
    },
    tt: {
        w: 200,
        readonly: true,
    },
    sin: {
        name: 'Insured Name',
    },
    a: {
        w: 80,
        field_format: { nocommas: true },
        name: 'AID',
    },
    quote: {
        w: 80,
        field_format: { nocommas: true },
        name: 'QID',
    },
    fm: {
        w: 250,
        name: 'Message',
        sortable: false,
    },
    td: {
        name: 'Duration',
        cellDataGetter: (data: string): any => {
            const val = parseFloat(data);
            return val > 60 ? `${(val / 60).toFixed(0)} mins` : `${val.toFixed(0)} secs`;
        },
        sortable: false,
    },
    lu: {
        name: 'Last Updated',
        cellType: 'datetime',
        sortable: false,
    },
};

const buildingpremiumsqlview: TableGlossaryType = {
    des: {
        w: 200,
    },
    pa: {
        w: 300,
    },
    cdis: {
        w: 200,
    },
    street: { w: 200 },
    city: { w: 150 },
    state: { w: 75 },
    county: { w: 150 },
    fips: { w: 80 },
    zip: {
        w: 80,
        field_format: { nocommas: true },
    },
    bl: { w: 150 },
    tiabl: { w: 150 },
    cl: { w: 150 },
    stl: { w: 150 },
    pool: { w: 150 },
    edpl: { w: 150 },
    apl: { w: 150 },
    bil: { w: 150 },
    aapao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    acpao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    atapao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    atcpao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    rapao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    rcpao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    rtapao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    rtcpao: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    actpt: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    rctpt: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    attpt: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    rttpt: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    bcli: {
        w: 160,
    },
    tiv: {
        w: 100,
    },
    loc: {
        w: 70,
    },
    bi: {
        w: 108,
    },
    odis: {
        w: 160,
    },
    tmcpm: { type: 'decimal', readonly: true, w: 70 },
    tmcp: { type: 'currency', readonly: true },
};
const accounttransactionbuildingdatasqlview: TableGlossaryType = {
    des: {
        w: 200,
    },
    pa: {
        w: 300,
    },
    cdis: {
        w: 200,
    },
    cpbt: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    ccp: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    cmcp: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    cifp: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    cap: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    ctp: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    cep: BC_DOLLAR_PCT_NET_GROSS_FIELD,
    cpp: BC_DOLLAR_PCT_NET_GROSS_FIELD,
};
const clearance: TableGlossaryType = {
    b: {
        w: 150,
        field_name: 'broker',
        short_name: 'Broker',
    },

    bo: {
        field_name: 'broker_office',
        short_name: 'Broker Office',
        id: 'bo',
        w: 250,
    },
    dti: {
        field_name: 'days_to_inception',
        short_name: 'DTI',
        id: 'dti',
    },
    datr: {
        field_name: 'date_and_time_received',
        short_name: 'Date/Time Rec.',
        id: 'datr',
    },
    c: {
        field_name: 'client',
        short_name: 'Client',
        id: 'c',
    },
};
const blanket_limit_groups: TableGlossaryType = {
    d: {
        w: 150,
    },
    nob: {
        w: 100,
        short_name: '# of Buildings',
    },
};
const building_quote_conditions: TableGlossaryType = {
    pa: {
        name: 'Address',
        w: 300,
        sortable: true,
    },
    des: {
        w: 250,
        sortable: true,
    },
    bi: {
        w: 120,
        sortable: true,
    },
};
const tivguidelines: TableGlossaryType = {
    ln: {
        field_name: 'location_number',
        name: 'Loc.',
        resizable: false,
        w: 100,
    },
    a: {
        field_name: 'address',
        name: 'Address',
        w: 300,
    },
    og: {
        field_name: 'occupancy_group',
        name: 'Occp.',
    },
    aoi: {
        field_name: 'arch_occupancy_type',
        name: 'Alternate Occupancy',
    },
    aci: {
        field_name: 'arch_construction',
        name: 'Arch Construction Type',
    },
    ctd: {
        field_name: 'construction_type_display',
        name: 'Construction',
        w: 200,
    },
    ppc: {
        field_name: 'ppc',
        name: 'PPC',
        w: 75,
    },
    is: {
        field_name: 'is_sprinklered',
        name: 'Sprinklered',
        w: 125,
    },
    tiv: {
        field_name: 'tiv',
        name: 'TIV',
        type: 'currency',
    },
    ajt: {
        field_name: 'adjusted_tiv_after_participation',
        name: 'Adjusted TIV',
        type: 'currency',
    },
    g: {
        field_name: 'guidance',
        name: 'Guidance',
        type: 'currency',
    },
    s: {
        field_name: 'status',
        w: 150,
        name: 'Status',
        classNameFn: (rowData: Record<string, any>): string => {
            return rowData.status ? 'review' : 'allowed';
        },
        readonly: true,
    },
    eqz: {
        field_name: 'arch_eq_zone',
        name: 'EQ Zone',
        w: 75,
    },
    tcz: {
        field_name: 'arch_tc_zone',
        name: 'Wind Zone',
        w: 75,
    },
    flhuc: {
        field_name: 'inland_flood_huc_zone',
        name: 'Inland Flood Zone',
        w: 75,
    },
};
const transactions: TableGlossaryType = {
    c: {
        name: 'Created Date',
        field_name: 'created',
        cellType: 'datetime',
    },
    tt: {
        // field_name: 'transaction_type',
        name: 'Type',
        w: 80,
        classNameFn: (rowData: Record<string, any>): string => {
            return rowData.is_active ? 'active' : 'inactive';
        },
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): string => {
            return rowData.modified_transaction_type;
        },
    },
    s: {
        name: 'Status',
        field_name: 'status_display',
        w: 80,
    },
    sid: {
        name: 'SID',
        field_name: 'submission_id',
        w: 80,
    },
    tlrdr: {
        field_name: 'last_rms_date_received',
        name: 'Last Modeled (RMS)',
        cellType: 'datetime',
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): string | null => {
            if (!rowData.submission_obj) return null;
            let { submission_obj } = rowData;
            let quote = _.find(submission_obj.quotes, q => {
                return q.id === submission_obj.primary_quote_id;
            });
            if (!quote) return null;
            return quote.last_rms_date_received;
        },
    },
    tladr: {
        field_name: 'last_air_date_received',
        name: 'Last Modeled (AIR)',
        cellType: 'datetime',
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): string => {
            if (!rowData.submission_obj) return null;

            let { submission_obj } = rowData;
            let quote = _.find(submission_obj.quotes, q => {
                return q.id === submission_obj.primary_quote_id;
            });
            if (!quote) return null;
            return quote.last_air_date_received;
        },
    },
    pbs: {
        w: 200,
        readonly: true,
        cellType: 'text',
        name: 'BOR Status',
        field_name: 'pending_bor_status',
        cellDataGetter: getBorStatusCellData,
    },
    pbsid: {
        w: 60,
        type: 'text',
        readonly: true,
        field_name: 'bor_incumbent_submission_id',
    },
    ed: {
        field_name: 'endorsement_description',
        w: 250,
        name: 'Description',
        readonly: (rowData: Record<string, any>): boolean => rowData.status === 'BOUND',
    },
    efd: {
        field_name: 'effective_date',
        name: 'Eff. Date',
        w: 90,
        cellType: 'date',
        readonly: (rowData: Record<string, any>): boolean => rowData.status !== 'OPEN',
        getMinDate: (rowData: Record<string, any>): string => rowData.effective_date_min,
        getMaxDate: (rowData: Record<string, any>): string => rowData.effective_date_max,
    },
    pd: {
        field_name: 'date_bound',
        name: 'Bound',
        w: 130,
        cellType: 'datetime',
    },
    bound_by: {
        field_name: 'bound_by.username',
        name: 'Bound By',
    },
    dk: {
        w: 130,
        name: 'Booked',
        cellType: 'datetime',
        classNameFn: (rowData: Record<string, any>): string => (!rowData.date_booked && rowData.provisional_date_booked ? 'pdk' : ''),
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): string => {
            if (rowData.date_booked) return rowData.date_booked;
            if (rowData.provisional_date_booked) return `${rowData.provisional_date_booked}`;
            return '';
        },
    },
    booked_by: {
        field_name: 'booked_by.username',
        name: 'Booked By',
    },
    ssid: {
        field_name: 'transaction_ordering_key',
        name: 'SID',
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): string => {
            return rowData.submission_id;
        },
        cellType: 'text',
    },
    tn: {
        field_name: 'transaction_number',
        name: 'Tx #',
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): string => {
            if (_.isNull(rowData.transaction_number)) return '';
            if (rowData.transaction_number_rev) return `${rowData.transaction_number} r${rowData.transaction_number_rev}`;
            else return `${rowData.transaction_number}`;
        },
        classNameFn: (): string => {
            return 'number';
        }, // used this to ensure right-justification.
        w: 70,
    },
    seqn: {
        field_name: 'sequence_number',
        name: 'Seq #',
        classNameFn: (): string => {
            return 'number';
        }, // used this to ensure right-justification.
        w: 70,
    },
    nob: {
        field_name: 'account_transaction_results_obj.number_of_buildings',
        name: '# of Bldgs.',
        w: 70,
        cellType: 'number',
    },
    tiv: {
        field_name: 'account_transaction_results_obj.tiv',
        name: 'TIV',
        type: 'currency',
    },
    cpbtd: {
        field_name: 'account_transaction_results_obj.charged_premium_before_tf_delta',
        name: 'Change in Premium',
        type: 'currency',
        cellDataGetter: (data: Record<string, any>, rowData: Record<string, any>): number | null => {
            if (!rowData.account_transaction_results_obj) return null;
            let current = rowData.account_transaction_results_obj.charged_premium_before_tf;
            let previous = rowData.is_premium_bearing ? rowData.account_transaction_results_obj.previous_charged_premium_before_tf : current;

            return current - previous;
        },
        readonly: true,
    },
    cb: {
        field_name: 'transaction_underwriter.username',
        name: 'Created By',
    },
    atbpt: {
        field_name: 'account_transaction_results_obj.air_technical_premium_before_tf',
        name: 'AIR Tech Premium',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    aclc: {
        field_name: 'account_transaction_results_obj.air_cat_loss_cost',
        name: 'AIR Cat Loss Cost',
        type: 'currency',
        delta: true,
    },
    amcnp: {
        field_name: 'account_transaction_results_obj.air_modeled_cat_net_premium',
        name: 'AIR Cat Modeled Net Premium',
        type: 'currency',
        delta: true,
    },
    amcp: {
        field_name: 'account_transaction_results_obj.air_modeled_cat_premium',
        name: 'AIR Modeled Cat Premium',
        type: 'currency',
        delta: true,
    },
    atcp: {
        field_name: 'account_transaction_results_obj.air_technical_cat_premium',
        name: 'AIR Technical Cat Premium',
        type: 'currency',
        delta: true,
    },
    atel: {
        field_name: 'account_transaction_results_obj.air_total_expected_loss',
        name: 'AIR Total Expected Loss',
        type: 'currency',
        delta: true,
    },
    rtbpt: {
        field_name: 'account_transaction_results_obj.rms_technical_premium_before_tf',
        name: 'RMS Tech Premium',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    rclc: {
        field_name: '_obj.rms_cat_loss_cost',
        name: 'RMS Cat Loss Cost',
        type: 'currency',
        delta: true,
    },
    rmcnp: {
        field_name: 'account_transaction_results_obj.rms_modeled_cat_net_premium',
        name: 'RMS Cat Modeled Net Premium',
        type: 'currency',
        delta: true,
    },
    rmcp: {
        field_name: 'account_transaction_results_obj.rms_modeled_cat_premium',
        name: 'RMS Modeled Cat Premium',
        type: 'currency',
        delta: true,
    },
    rtcp: {
        field_name: 'account_transaction_results_obj.rms_technical_cat_premium',
        name: 'RMS Technical Cat Premium',
        type: 'currency',
        delta: true,
    },
    rtel: {
        field_name: 'account_transaction_results_obj.rms_total_expected_loss',
        name: 'RMS Total Expected Loss',
        type: 'currency',
        delta: true,
    },
    cpbt: {
        field_name: 'account_transaction_results_obj.charged_premium_before_tf',
        name: 'Total Charged',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    r: {
        field_name: 'review_date',
        name: 'Review Date',
        type: 'datetime',
    },
    review_by: {
        field_name: 'review_by.username',
        name: 'Review By',
    },
    ac: {
        field_name: 'accounting_date',
        name: 'Accounting Date',
        type: 'datetime',
    },
    ifee: {
        field_name: 'inspection_fee',
        name: 'Inspection Fee',
        type: 'currency',
        readonly: (rowData: Record<string, any>): boolean => rowData.status === 'BOUND' || rowData.modified_transaction_type === 'NPB EN',
    },
    ept: {
        field_name: 'endorsement_pricing_type_display',
        name: 'Endorsement Pricing Type',
        readonly: true,
    },
    mf: {
        field_name: 'modeling_fee',
        name: 'Modeling Fee',
        type: 'currency',
        readonly: (rowData: Record<string, any>): boolean => rowData.status === 'BOUND' || rowData.modified_transaction_type === 'NPB EN',
    },
    ebepo: {
        field_name: 'equipment_breakdown_external_premium_delta_override',
        name: 'EB External Premium Delta Override',
        type: 'currency',
        readonly: (rowData: Record<string, any>): boolean => rowData.status === 'BOUND' || rowData.modified_transaction_type === 'NPB EN',
    },
    ccp: {
        field_name: 'account_transaction_results_obj.charged_cat_premium',
        name: 'Charged CAT premium',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    cap: {
        field_name: 'account_transaction_results_obj.charged_aop_premium',
        name: 'Charged AOP premium',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    ceb: {
        field_name: 'account_transaction_results_obj.charged_eb_premium',
        name: 'Charged EB premium',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    ct: {
        field_name: 'account_transaction_results_obj.charged_terror_premium',
        name: 'Charged Terror',
        type: 'currency',
        readonly: true,
        delta: true,
    },

    cp: {
        field_name: 'account_transaction_results_obj.charged_pee_premium',
        name: 'Charged PEE',
        type: 'currency',
        readonly: true,
        delta: true,
    },
    ar: {
        field_name: 'account_transaction_results_obj.account_rate',
        name: 'Account Rate',
        type: 'decimal',
        readonly: true,
    },
    ebar: {
        field_name: 'account_transaction_results_obj.eb_account_rate',
        name: 'EB Account Rate',
        type: 'decimal',
        readonly: true,
    },
    po: {
        field_name: 'account_transaction_results_obj.priced_on',
        name: 'Priced On',
        type: 'datetime',
        readonly: true,
    },
    cpuo: {
        field_name: 'account_transaction_results_obj.charged_price_updated_on',
        name: 'Charged Price Updated On',
        type: 'datetime',
        readonly: true,
    },
    pred: {
        field_name: 'print_endorsement_description',
        name: 'Print endorsement description',
        w: 250,
        readonly: (rowData: Record<string, any>) => rowData.status === 'BOUND',
    },
    ofac: {
        name: 'OFAC Status',
        field_name: 'ofac_status',
        w: 75,
        readonly: true,
    },
    ofaca: {
        name: 'OFAC Approver',
        field_name: 'ofac_approver',
        w: 75,
        readonly: true,
    },
};
const quotes: TableGlossaryType = {
    i: ID_COL_PROPS,
    ip: {
        cellDataGetter: (quoteId: number, rowData: Record<string, any>, key: string, extraData: Record<string, any>): string => {
            return extraData.primary_quote_id === rowData.id ? 'Yes' : '';
        },
        name: 'Primary',
        w: 90,
        resizable: false,
    },
    sd: {
        field_name: 'status_display',
        name: 'Status',
    },
    d: {
        field_name: 'description',
        name: 'Description',
        w: 160,
    },
    lrdr: {
        field_name: 'last_rms_date_received',
        name: 'Last Modeled (RMS)',
        cellDataGetter: (value: number | null, _rowData: unknown, _fieldName: string, { enableRms }): number => {
            if (enableRms === false) {
                return null;
            }

            if (value === undefined || value === null) {
                return 0;
            }
            return value;
        },
        cellType: 'datetime',
    },
    ladr: {
        field_name: 'last_air_date_received',
        name: 'Last Modeled (AIR)',
        cellType: 'datetime',
    },
    nob: {
        field_name: 'number_of_buildings',
        name: '# Buildings',
        w: 110,
        cellDataGetter: (quoteId: number, rowData): number => {
            return rowData.final_premium && rowData.final_premium['number_of_buildings'];
        },
    },
    c: {
        field_name: 'created',
        name: 'Date Added',
        cellType: 'date',
    },
    tapf: {
        field_name: 'reprice.aop_technical_premium',
        name: 'AOP',
        type: 'currency',
    },
    atcpf: {
        field_name: 'reprice.air_total_cat_premium_final',
        name: 'CAT (AIR)',
        type: 'currency',
    },
    rtcpf: {
        field_name: 'reprice.rms_total_cat_premium_final',
        name: 'CAT (RMS)',
        cellDataGetter: (value: number | null, _rowData: unknown, _fieldName: string, { enableRms }): number => {
            if (enableRms === false) {
                return null;
            }

            if (value === undefined || value === null) {
                return 0;
            }
            return value;
        },
        type: 'currency',
    },
    atp: {
        field_name: 'reprice.air_technical_premium',
        name: 'Total (AIR)',
        type: 'currency',
    },
    rtp: {
        field_name: 'reprice.rms_technical_premium',
        name: 'Total (RMS)',
        cellDataGetter: (value: number | null, _rowData: unknown, _fieldName: string, { enableRms }): number => {
            if (enableRms === false) {
                return null;
            }

            if (value === undefined || value === null) {
                return 0;
            }
            return value;
        },
        type: 'currency',
    },
    apot: {
        field_name: 'reprice.air_pct_of_technical',
        name: '% of Technical (AIR)',
        w: 150,
        cellDataGetter: (value: number | null, _rowData: Record<string, any>): number => {
            if (value === undefined || value === null) {
                return 0;
            }
            return value;
        },
        type: 'percent',
    },
    rpot: {
        field_name: 'reprice.rms_pct_of_technical',
        name: '% of Technical (RMS)',
        w: 150,
        cellDataGetter: (value: number | null, _rowData: unknown, _fieldName: string, { enableRms }): number => {
            if (enableRms === false) {
                return null;
            }

            if (value === undefined || value === null) {
                return 0;
            }
            return value;
        },
        type: 'percent',
    },
    rv: {
        field_name: 'reprice.validations',
        name: 'Validation issues',
        type: 'validations',
        w: 180,
    },
    cpbt: {
        field_name: 'reprice.charged_premium_before_tf',
        name: 'Charged Premium',
        type: 'currency',
    },
};

const RAE_BOUND_ACTIONS = [
    {
        actionFn: 'onOpenCarousel',
        hint: 'Preview',
        className: 'tw-min-w-4 tw-w-1/6',
        extraPropsFn: getExtraDocumentProps,
    },
    {
        icon: 'icon-download3',
        actionFn: 'onDownloadDocument',
        hint: 'Download',
        className: 'tw-min-w-4 tw-w-1/6',
    },
];
const DEFAULT_ACTIONS = [
    ...RAE_BOUND_ACTIONS,
    {
        icon: 'icon-delete',
        actionFn: 'onDeleteDocument',
        className: 'red-txt tw-min-w-4 tw-w-1/6',
        hint: 'Delete',
    },
    {
        text: extraDocumentRowActionText,
        actionFn: 'onExtraDocumentRowAction',
        isHidden: extraDocumentRowActionIsHidden,
        className: 'size-4',
        hint: extraDocumentRowActionHint,
    },
];

const documents: TableGlossaryType = {
    dt: {
        field_name: 'document_type',
        sort_field_name: 'document_type_display',
        name: 'Type',
        w: 150,
        constants_choices: 'DOCUMENT_TYPES',
    },
    d: {
        field_name: 'description',
        name: 'Description',
        w: 250,
    },
    of: {
        field_name: 'original_filename',
        w: 250,
        name: 'Filename',
        resizable: true,
        classNameFn: (rowData: Record<string, any>): string => {
            return rowData.missing ? 'missing' : '';
        },
        readonly: true,
        sortable: true,
    },
    c: {
        field_name: 'created',
        name: 'Date Uploaded',
        cellType: 'datetime',
        readonly: true,
    },
    v: {
        field_name: 'av_status',
        w: 125,
        name: 'AntiVirus Scan',
        readonly: true,
        constants_choices: 'ANTIVIRUS_SCAN_STATUS',
    },
    a: {
        field_name: 'actions',
        name: 'Actions',
        cellType: 'actions',
        fixed_w: 180,
        actions: DEFAULT_ACTIONS,
    },
    rae_bound_actions: {
        field_name: 'actions',
        name: 'Actions',
        cellType: 'actions',
        fixed_w: 180,
        actions: RAE_BOUND_ACTIONS,
    },
};
const building_locations: TableGlossaryType = {
    id: {
        field_name: 'id',
        short_name: 'ID',
        id: 'id',
    },
    pa: {
        field_name: 'pretty_address',
        short_name: 'Address',
        id: 'pa',
        w: 450,
    },
    a: {
        field_name: 'actions',
        name: 'Actions',
        cellType: 'actions',
        fixed_w: 180,
        actions: [
            {
                actionFn: 'onEditBuildingCoverage',
                text: 'Edit Building',
                extraPropsFn: getExtraDocumentProps,
            },
        ],
    },
};

const regional_cat_summary: TableGlossaryType = {
    q: {
        w: 200,
        cellDataGetter: (value: number): string => {
            return value.toString();
        },
        field_name: 'quote_id',
        name: 'Coverage Option',
        readonly: true,
    },
    r: {
        w: 200,
        field_name: 'region',
        name: 'Region',
        readonly: true,
        sort_field: 'arch_region__name',
    },
    p: {
        w: 180,
        field_name: 'peril',
        name: 'Peril',
        readonly: true,
        sort_field: 'arch_region__arch_peril__name',
    },
    ga: {
        w: 160,
        field_name: 'gross_aal',
        name: 'AIR Gross AAL',
        readonly: true,
        type: 'currency',
    },
    ld: {
        w: 180,
        field_name: 'layer_description',
        name: 'Layer Description',
        readonly: true,
        disableColumnFilter: true,
        sort_field: 'quote_id',
    },
    rp2gp: {
        w: 200,
        field_name: 'return_period_250_gross_pml',
        name: 'AIR PML Gross 250 Yr',
        readonly: true,
        type: 'currency',
    },
    rp1gp: {
        w: 180,
        field_name: 'return_period_1000_gross_pml',
        name: 'AIR PML Gross 1000 Yr',
        readonly: true,
        type: 'currency',
    },
};

export const COMBINED_TABLE_GLOSSARY = {
    accounttransactiondatasqlview,
    buildingcoveragegridsqlview,
    buildingdatasqlview,
    backgroundtask,
    buildingpremiumsqlview,
    accounttransactionbuildingdatasqlview,
    clearance,
    blanket_limit_groups,
    building_quote_conditions,
    tivguidelines,
    transactions,
    quotes,
    documents,
    building_locations,
    regional_cat_summary,
};

for (let id of PAGED_TABLES_ALL_COLUMNS_LIST.building_quote_conditions) {
    if (!building_quote_conditions[id]) {
        building_quote_conditions[id] = {
            resizable: true,
            w: DEFAULT_QC_COL_WIDTH,
            colIsSettable: true,
            cellType: 'checkbox',
            name: id,
            id: id,
            readonly: false,
            aggregate: '__sum',
            field_name: 'quote_condition_' + id.toLowerCase(),
            colNameFn: (props: Record<string, any>) => {
                return getQuoteConditionLabel(id, props.extraData.quoteConditionsLabelsByField);
            },
        };
    }
}
