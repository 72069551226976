import { useRef, useState } from 'react';
import cx from 'classnames';
import { isEqual } from 'lodash';

import { useAppContext } from '../../hooks/context';
import { useLazyComparableSubmissionsQuery } from '../../services/apiSlice';
import { ComparableSubmissionsOptions, ComparableSubmissionsResult, ComparableSubmissionsStats, PolicyCoverageType } from '../../ts-types/ApiTypes';
import { TwGrid, SubHeader, FormattedValue, TwEmptyRow, TwBreak } from './PricingGrid';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { Select, Switch } from '@archinsurance-viki/property-jslib/src/components/inputs/v2';
import { useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import { useAppSelector } from '../../hooks/redux';
import { formatBasicDate, formatQuarter, getEndOfQuarter, getStartOfQuarter } from '@archinsurance-viki/property-jslib/src/utils/date-helpers';
import { SelectItem } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/Select';
import { useStateObject, StateObjectUpdater } from '@archinsurance-viki/property-jslib/src/hooks/util';

/**
 * Select choices for quarter pickers, in the form of `[quarters ago, formatted quarter string]`.
 * We only include the current quarter and the previous 4 quarters.
 */
const QUARTER_CHOICES: SelectItem<number>[] = [0, -1, -2, -3, -4].map(q => [q, formatQuarter(q)]);

const PortfolioComparablesPanel = () => {
    const [quarterRangeStart, setQuarterRangeStart] = useState(QUARTER_CHOICES.at(0));
    const [quarterRangeEnd, setQuarterRangeEnd] = useState(QUARTER_CHOICES.at(0));
    const [filters, updateFilter] = useStateObject<ComparablesFilters>({
        filterLayered: true,
        filterWindDeductibleType: false,
        filterWindDeductibleAmount: false,
        filterOccupancy: false,
        filterConstruction: true,
        filterZip: false,
        filterCounty: false,
        filterState: false,
        filterRegion: true,
    });

    return (
        <div className="tw-p-2">
            <EffectiveDateRange
                quarterRangeStart={quarterRangeStart}
                onQuarterStartChange={setQuarterRangeStart}
                quarterRangeEnd={quarterRangeEnd}
                onQuarterEndChange={setQuarterRangeEnd}
            />
            <ComparablesFiltersGrid filters={filters} updateFilter={updateFilter} />
            <ComparablesResultsGrid quarterRangeStart={quarterRangeStart[0]} quarterRangeEnd={quarterRangeEnd[0]} filters={filters} />
        </div>
    );
};

export default PortfolioComparablesPanel;

const EffectiveDateRange = ({
    quarterRangeStart,
    onQuarterStartChange,
    quarterRangeEnd,
    onQuarterEndChange,
}: {
    quarterRangeStart: SelectItem<number>;
    onQuarterStartChange: (item: SelectItem<number>) => void;
    quarterRangeEnd: SelectItem<number>;
    onQuarterEndChange: (item: SelectItem<number>) => void;
}) => {
    const onStartChange = (start: SelectItem<number>) => {
        onQuarterStartChange(start);
        // ensure that the end is always >= the start
        if (start[0] > quarterRangeEnd[0]) {
            onQuarterEndChange(start);
        }
    };

    const onEndChange = (end: SelectItem<number>) => {
        onQuarterEndChange(end);
        // ensure that the end is always >= the start
        if (end[0] < quarterRangeStart[0]) {
            onQuarterStartChange(end);
        }
    };

    return (
        <>
            <div className="tw-pb-1 tw-font-semibold">Effective Date Range</div>
            <div className="tw-flex tw-items-center tw-pb-4">
                <Select items={QUARTER_CHOICES} selectedItem={quarterRangeStart} onChange={onStartChange} />
                <span className="tw-px-2">to</span>
                <Select items={QUARTER_CHOICES} selectedItem={quarterRangeEnd} onChange={onEndChange} />
            </div>
        </>
    );
};

type ComparablesFilters = {
    filterLayered: boolean;
    filterWindDeductibleType: boolean;
    filterWindDeductibleAmount: boolean;
    filterOccupancy: boolean;
    filterConstruction: boolean;
    filterZip: boolean;
    filterCounty: boolean;
    filterState: boolean;
    filterRegion: boolean;
};

const ComparablesFiltersGrid = ({ filters, updateFilter }: { filters: ComparablesFilters; updateFilter: StateObjectUpdater<ComparablesFilters> }) => {
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const { finalPremium } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();

    const windDeductibleOptions = CONSTANTS.CAT_WIND_DEDUCTIBLE;

    const currentAccountLayered = getLayeredLabel(policyCoverage);
    const currentAccountWindDeductibleType = windDeductibleOptions.find(o => o.value === policyCoverage.cw_deductible)?.display ?? policyCoverage.cw_deductible;
    const currentAccountWindDeductibleAmount = `${(policyCoverage.cw_deductible_pct_amt * 100).toFixed(2)}%`;
    const currentAccountOccupancy = finalPremium.predominant_occupancy;
    const currentAccountConstruction = CONSTANTS.CONSTRUCTION_TYPES_SHORT_NAMES[finalPremium.predominant_construction_type];
    const currentZip = finalPremium.predominant_zipcode;
    const currentCounty = finalPremium.predominant_county;
    const currentState = finalPremium.predominant_state;
    const currentRegion = CONSTANTS.REPORTING_REGIONS[finalPremium.predominant_region];

    const {
        filterLayered,
        filterWindDeductibleType,
        filterWindDeductibleAmount,
        filterOccupancy,
        filterConstruction,
        filterZip,
        filterCounty,
        filterState,
        filterRegion,
    } = filters;

    const mutuallyExclusiveFilters: Array<keyof ComparablesFilters> = ['filterZip', 'filterCounty', 'filterState', 'filterRegion'];

    const onFilterChangeValidateMutuallyExclusivity = (filter: keyof ComparablesFilters) => {
        const wasEnabled = filters[filter];
        const setFilter = updateFilter(filter);
        setFilter(!wasEnabled);

        if (!wasEnabled) {
            // Turn off all other filters other than the one that's being enabled
            mutuallyExclusiveFilters
                .filter(filterName => filter !== filterName)
                .forEach(filterName => {
                    updateFilter(filterName)(false);
                });
        }
    };

    return (
        <>
            <div className="tw-font-semibold tw-pb-1">Filters</div>
            <TwGrid className="tw-grid-cols-[max-content,max-content,auto] tw-w-full tw-pb-4">
                <SubHeader>Field</SubHeader>
                <SubHeader className="tw-pr-3">Enable</SubHeader>
                <SubHeader>This Account</SubHeader>

                <FilterRow label="Layer" enabled={filterLayered} onChange={updateFilter('filterLayered')} currentSubmissionValue={currentAccountLayered} />
                <FilterRow
                    label="Wind Deductible Type"
                    enabled={filterWindDeductibleType}
                    onChange={updateFilter('filterWindDeductibleType')}
                    currentSubmissionValue={currentAccountWindDeductibleType}
                />
                <FilterRow
                    label="Wind Deductible Amount"
                    enabled={filterWindDeductibleAmount}
                    onChange={updateFilter('filterWindDeductibleAmount')}
                    currentSubmissionValue={currentAccountWindDeductibleAmount}
                />
                <FilterRow
                    label="Occupancy"
                    enabled={filterOccupancy}
                    onChange={updateFilter('filterOccupancy')}
                    currentSubmissionValue={currentAccountOccupancy}
                />
                <FilterRow
                    label="Construction"
                    enabled={filterConstruction}
                    onChange={updateFilter('filterConstruction')}
                    currentSubmissionValue={currentAccountConstruction}
                />
                <TwBreak />
                <TwEmptyRow />
                <FilterRow
                    label="Zip Code"
                    enabled={filterZip}
                    onChange={() => onFilterChangeValidateMutuallyExclusivity('filterZip')}
                    currentSubmissionValue={currentZip}
                />
                <FilterRow
                    label="County"
                    enabled={filterCounty}
                    onChange={() => onFilterChangeValidateMutuallyExclusivity('filterCounty')}
                    currentSubmissionValue={currentCounty}
                />
                <FilterRow
                    label="State"
                    enabled={filterState}
                    onChange={() => onFilterChangeValidateMutuallyExclusivity('filterState')}
                    currentSubmissionValue={currentState}
                />
                <FilterRow
                    label="Region"
                    enabled={filterRegion}
                    onChange={() => onFilterChangeValidateMutuallyExclusivity('filterRegion')}
                    currentSubmissionValue={currentRegion}
                />
            </TwGrid>
        </>
    );
};

const FilterRow = ({
    label,
    enabled,
    onChange,
    currentSubmissionValue,
}: {
    label: string;
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    currentSubmissionValue: string;
}) => {
    return (
        <>
            <label className="tw-pr-3">{label}</label>
            <Switch className="tw-mr-3" value={enabled} onChange={onChange} />
            <div>{currentSubmissionValue}</div>
        </>
    );
};

const ComparablesResultsGrid = ({
    quarterRangeStart,
    quarterRangeEnd,
    filters,
}: {
    quarterRangeStart: number;
    quarterRangeEnd: number;
    filters: ComparablesFilters;
}) => {
    // Experimental UI feature that keeps the results visible even when the params change,
    // but applies a greyed out overlay to indicate that the results are stale.
    // The normal behavior is for the results to be hidden altogether when they are stale.
    const useStaleDataOverlay = useAppSelector(state => !!state.global.featureFlags.experimental_ui);

    const { currentSubmissionId, currentQuoteId } = useAppContext();
    const [loadComparableSubmissions, { data, isFetching, isUninitialized }] = useLazyComparableSubmissionsQuery();

    const latestParams = useRef<ComparableSubmissionsOptions>();
    const currentParams: ComparableSubmissionsOptions = {
        submissionId: currentSubmissionId,
        quoteId: currentQuoteId,
        effective_date_start: formatBasicDate(getStartOfQuarter(quarterRangeStart)),
        effective_date_end: formatBasicDate(getEndOfQuarter(quarterRangeEnd)),
        filter_layered: filters.filterLayered,
        filter_wind_deductible_type: filters.filterWindDeductibleType,
        filter_wind_deductible_amt: filters.filterWindDeductibleAmount,
        filter_occupancy: filters.filterOccupancy,
        filter_construction: filters.filterConstruction,
        filter_zip: filters.filterZip,
        filter_county: filters.filterCounty,
        filter_state: filters.filterState,
        filter_region: filters.filterRegion,
    };
    const paramsChanged = !isUninitialized && !isEqual(latestParams.current, currentParams);

    const onLoad = () => {
        loadComparableSubmissions(currentParams);
        latestParams.current = currentParams;
    };

    const showData = useStaleDataOverlay ? !!data : data && !paramsChanged;
    const showStaleDataOverlay = useStaleDataOverlay ? paramsChanged : false;

    return (
        <>
            <div className="tw-font-semibold tw-pb-1">Rates (Excluding WDBD)</div>
            {isUninitialized && (
                <ComparablesResultsMessage message="No results loaded yet.">
                    <ComparablesResultsLoadButton text="Load results" onLoad={onLoad} />
                </ComparablesResultsMessage>
            )}
            {paramsChanged && (
                <ComparablesResultsMessage message="Filters changed.">
                    <ComparablesResultsLoadButton text="Reload results" onLoad={onLoad} />
                </ComparablesResultsMessage>
            )}
            {isFetching && (
                <ComparablesResultsMessage message="Loading results...">
                    <Spinner />
                </ComparablesResultsMessage>
            )}
            {showData && (
                <div className="tw-relative tw-w-full">
                    <TwGrid className="tw-grid-cols-[max-content,1fr,1fr,1fr,1fr] tw-w-1/2">
                        <SubHeader>Statistic</SubHeader>
                        <SubHeader className="tw-text-right" tooltip="Outstanding">
                            O/S
                        </SubHeader>
                        <SubHeader className="tw-text-right" tooltip="Not Taken Up">
                            NTU
                        </SubHeader>
                        <SubHeader className="tw-text-right">Bound</SubHeader>
                        <SubHeader className="tw-text-right">All</SubHeader>

                        <ComparablesResultsRow label="Average Rate" data={data} stat="average" />
                        <ComparablesResultsRow label="Min" data={data} stat="minimum" />
                        <ComparablesResultsRow label="1st Quartile" data={data} stat="first_quartile" />
                        <ComparablesResultsRow label="3rd Quartile" data={data} stat="third_quartile" />
                        <ComparablesResultsRow label="Max" data={data} stat="maximum" />
                        <ComparablesResultsRow integer label="Count" data={data} stat="count" />
                    </TwGrid>
                    {showStaleDataOverlay && <div className="tw-absolute tw-inset-0 tw-bg-white/50 tw-pointer-events-none" />}
                </div>
            )}
        </>
    );
};

const ComparablesResultsMessage = ({ message, children }: { message: string; children: React.ReactNode }) => (
    <span className="tw-flex tw-items-center tw-space-x-2 tw-pb-2">
        <span>{message}</span>
        {children}
    </span>
);

const ComparablesResultsLoadButton = ({ text, onLoad }: { text: string; onLoad: () => void }) => (
    <span
        className={cx(
            'tw-bg-blue-primary tw-rounded-lg tw-shadow tw-px-2 tw-py-1',
            'tw-uppercase tw-tracking-widest tw-text-3xs tw-font-bold tw-text-white tw-cursor-pointer'
        )}
        onClick={onLoad}
    >
        {text}
    </span>
);

const ComparablesResultsRow = ({
    integer,
    label,
    data,
    stat,
}: {
    integer?: boolean;
    label: string;
    data: ComparableSubmissionsResult;
    stat: keyof ComparableSubmissionsStats;
}) => {
    return (
        <>
            <label>{label}</label>
            <ComparablesResultsCell integer={integer} data={data} stat={stat} column="OUTSTANDING" />
            <ComparablesResultsCell integer={integer} data={data} stat={stat} column="NOT_TAKEN_UP" />
            <ComparablesResultsCell integer={integer} data={data} stat={stat} column="BOUND" />
            <ComparablesResultsCell integer={integer} data={data} stat={stat} column="ALL" />
        </>
    );
};

const ComparablesResultsCell = ({
    integer = false,
    data,
    stat,
    column,
}: {
    integer?: boolean;
    data: ComparableSubmissionsResult;
    stat: keyof ComparableSubmissionsStats;
    column: keyof ComparableSubmissionsResult;
}) => {
    const rawValue = data[column][stat];
    const value = integer ? rawValue ?? 0 : Math.round(rawValue * 100) / 100;

    return (
        <div className="tw-justify-self-end tw-pl-3">
            <FormattedValue value={value} format={{ type: 'number', decimals: integer ? 0 : 2, delimiter: ',' }} />
        </div>
    );
};

const getLayeredLabel = (policyCoverage: PolicyCoverageType) => {
    if (policyCoverage.policy_is_layered) {
        if (policyCoverage.occurrence_attachment > 0) return 'Excess';
        else return 'Primary';
    } else return 'Ground Up';
};
