import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormFormatSelection } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormFormatSelection';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuoteFinalPremium } from '../../../hooks/quotes';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField, selectDeductibleFormats } from '../utils/form';
import { getSharedAndLayeredDescription, OCCURRENCE_FIELDS, trimAndParse } from '../utils/coverage-utils';
import { DeductibleFormatsType, MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { useChangeEffect } from '@archinsurance-viki/property-jslib/src/hooks/util';

export const SharedAndLayered = () => {
    const { finalPremium } = useQuoteFinalPremium();
    const deductibleFormats = useAppSelector(state => selectDeductibleFormats(state));
    const occurrenceValues = useWatch<PolicyTermsFormValues, typeof OCCURRENCE_FIELDS>({
        name: OCCURRENCE_FIELDS,
    });
    const [
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
    ] = occurrenceValues;

    // generate occurrence description based on form state
    const description = getSharedAndLayeredDescription(
        {
            policy_is_layered,
            occurrence_participation_fmt,
            occurrence_participation: +occurrence_participation,
            occurrence_participation_dollar_amt: trimAndParse(occurrence_participation_dollar_amt),
            occurrence_attachment: trimAndParse(occurrence_attachment),
            occurrence_limit: trimAndParse(occurrence_limit),
        },
        +finalPremium?.tiv
    );

    const {
        formState: { dirtyFields },
        resetField,
        setValue,
    } = useFormContext<PolicyTermsFormValues>();

    // PRUW-5907 - Mimic old UI behavior by setting fields to blank initially
    // Note: avoid clearing the limit + attachment fields if starts with Policy is layered === true
    const policyIsLayered = useWatch<PolicyTermsFormValues>({ name: 'policy_is_layered' });
    useChangeEffect(policyIsLayered, (next, prev) => {
        if (Object.keys(dirtyFields).length === 1 && prev === false && next) {
            setValue('occurrence_limit', '', { shouldDirty: true });
            setValue('occurrence_attachment', '', { shouldDirty: true });
        } else if (prev && next === false) {
            resetField('occurrence_limit');
            resetField('occurrence_attachment');
        }
    });

    return (
        <>
            <div className="info-rows">
                <PolicyTermsFormField>
                    <FormFormatSelection<DeductibleFormatsType, PolicyTermsFormValues>
                        name="occurrence_participation_fmt"
                        formats={deductibleFormats}
                        render={() =>
                            !(occurrence_participation_fmt === 'PERCENTAGE') ? (
                                <PolicyTermsFormField hideLabel>
                                    <FormInput<PolicyTermsFormValues>
                                        name="occurrence_participation_dollar_amt"
                                        dollarBefore
                                        maskOptions={MASKS['CURRENCY_INTEGER']}
                                    />
                                </PolicyTermsFormField>
                            ) : (
                                <PolicyTermsFormField hideLabel>
                                    <FormInput<PolicyTermsFormValues>
                                        name="occurrence_participation"
                                        maskOptions={{ ...MASKS['PERCENTAGE_POSITIVE'], numeralDecimalScale: 5 }}
                                        percentAfter
                                    />
                                </PolicyTermsFormField>
                            )
                        }
                    />
                </PolicyTermsFormField>

                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues>
                        name="policy_is_layered"
                        items={[
                            { display: 'Yes', value: true },
                            { display: 'No', value: false },
                        ]}
                    />
                </PolicyTermsFormField>

                {policy_is_layered && (
                    <>
                        <PolicyTermsFormField>
                            <FormInput<PolicyTermsFormValues> name="occurrence_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                        </PolicyTermsFormField>

                        <PolicyTermsFormField>
                            <FormInput<PolicyTermsFormValues> name="occurrence_attachment" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                        </PolicyTermsFormField>
                    </>
                )}

                {description && (
                    <>
                        <div />
                        <div className="note-info-blocks" style={{ marginBottom: 0, width: 'fit-content' }}>
                            <b style={{ fontSize: '10px', marginRight: '8px', width: 'fit-content' }}>NOTE:</b>
                            <span className="tw-cursor-default">{description}</span>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
