import * as React from 'react';

import classNames from 'classnames';
import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import { PagedTableDataType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { processGridRequestWithId } from '@archinsurance-viki/property-jslib/src/utils/api/RequestUtils';
import { BuildingDataType, SubmissionDataType, blockPropTypes } from '../../../../ts-types/DataTypes';
import {
    ADDITIONAL_BUILDING_TABLE_DEFS,
    ADDITIONAL_STRUCTURES,
    BUILDING_DETAILS,
    BUILDING_DOCS,
    EXTERNAL_DATA,
    CONSTRUCTION_DETAILS,
    BUILDING_LIMITS,
    ROOF_ATTRIBUTES,
    WALL_ATTRIBUTES,
    OTHER_EXPOSURE_DATA,
    BUILDING_COVERAGE_TABLE_DEFS,
} from '../../../../constants/BuildingConstants';
import { processColumnsForInfoBlocks } from '@archinsurance-viki/property-jslib/src/utils/tables/columns';
import { formatGeoDataForSave } from '@archinsurance-viki/property-jslib/src/utils/api-helpers';
import { getAddressStringFromObject } from '@archinsurance-viki/property-jslib/src/utils/address-helpers';

import MapInfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/MapInfoBlock';
import { DEFAULT_ZOOM_IN, DEFAULT_ZOOM_OUT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { Types } from '../../../../ts-types/viki-types';

type propTypes = {
    tableData: PagedTableDataType;
    ENV: Types.Env;
    CONSTANTS: Types.Constants;
    onPersistTableRows?: (persistData: Record<string, any>) => void;
    readonly: boolean;
    currentSubmission: SubmissionDataType;
    currentBuilding: BuildingDataType;
    currentQuote: Record<string, any>;
    selectedRowIndex: number;
    onAddOrEditAdditionalStructure: (structure: Record<string, any>, data: Record<string, any>) => void;
    onSaveFn: (...params: any) => any;
    onRowSelected: RowSelectedFnType;
};

type stateTypes = {
    campusView: boolean;
    skyView: boolean;
    bingView: boolean;
};

const INFO_BLOCKS = [
    BUILDING_DETAILS,
    CONSTRUCTION_DETAILS,
    ROOF_ATTRIBUTES,
    WALL_ATTRIBUTES,
    EXTERNAL_DATA,
    OTHER_EXPOSURE_DATA,
    BUILDING_LIMITS,
    ADDITIONAL_STRUCTURES,
    BUILDING_DOCS,
];

export default class BuildingsBottomPanelApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            campusView: false,
            skyView: false,
            bingView: false,
        };
    }

    onToggleBingView = () => {
        this.setState({ bingView: !this.state.bingView, campusView: false, skyView: false });
    };

    onToggleCampusView = () => {
        this.setState({ campusView: !this.state.campusView, skyView: false, bingView: false });
    };

    onToggleSkyView = () => {
        this.setState({ skyView: !this.state.skyView, campusView: false, bingView: false });
    };

    handleMapSave = (latitude: number, longitude: number) => {
        let { onPersistTableRows, tableData, currentBuilding } = this.props;
        if (!onPersistTableRows) {
            console.error('onPersistTableRows is null, no save');
            return;
        }

        let request = processGridRequestWithId(currentBuilding.id, formatGeoDataForSave(latitude, longitude), tableData.rowData);
        onPersistTableRows(request);
    };

    renderBlocks = () => {
        let { tableData, currentBuilding, onAddOrEditAdditionalStructure, onSaveFn, readonly, ENV, CONSTANTS } = this.props;
        if (!tableData || !currentBuilding) {
            return null;
        }

        let helperFunctions = {
            handleAddRow: onAddOrEditAdditionalStructure,
            handleEditRow: onAddOrEditAdditionalStructure,
            handleSave: onSaveFn,
        };

        let defs = processColumnsForInfoBlocks({ ...ADDITIONAL_BUILDING_TABLE_DEFS, ...BUILDING_COVERAGE_TABLE_DEFS }, tableData.glossaryData.map, CONSTANTS);

        return INFO_BLOCKS.map((block: blockPropTypes) => {
            return (
                <InfoBlock
                    ENV={ENV}
                    rowObjectGetter="currentBuilding"
                    parentProps={this.props}
                    {...block}
                    glossary={defs}
                    readonly={onSaveFn && readonly}
                    helperFunctions={helperFunctions}
                />
            );
        });
    };

    render() {
        let { tableData, currentBuilding, onRowSelected, readonly, selectedRowIndex } = this.props;
        if (!tableData || !currentBuilding) {
            return null;
        }

        let { rows, rowData } = this.props.tableData;

        let { campusView, skyView, bingView } = this.state;

        let headerButtons = [];
        const vexcelEndpoint = `${window.location.origin}/api/v1/fetch_vexcel_tile`;

        if (currentBuilding && currentBuilding.geo && currentBuilding.geo.coordinates && currentBuilding.geo.coordinates.length) {
            headerButtons.push(
                <button key="bing" className={classNames('text-button', bingView ? 'blue' : 'default')} onClick={this.onToggleBingView}>
                    Bing 45°
                </button>
            );
        }
        headerButtons.push(
            <button key="campus" className={classNames('text-button', campusView ? 'blue' : 'default')} onClick={this.onToggleCampusView}>
                Schedule View
            </button>
        );
        headerButtons.push(
            <button key="sky" className={classNames('text-button', skyView ? 'blue' : 'default')} onClick={this.onToggleSkyView}>
                Sky View
            </button>
        );

        let mapDisplay = (
            <div className="panel-fixed">
                <MapInfoBlock
                    vexcelEndpoint={vexcelEndpoint}
                    mapConfigs={{ CAMPUS: campusView, BING: bingView, SKY: skyView }}
                    locations={rows.map((id: number) => rowData[id])}
                    currentIndex={selectedRowIndex}
                    onMarkerClick={onRowSelected}
                    readonly={readonly}
                    bingView={bingView}
                    zoom={skyView ? DEFAULT_ZOOM_OUT : campusView ? 'fit' : DEFAULT_ZOOM_IN}
                    saveFn={this.handleMapSave}
                    headerButtons={headerButtons}
                />
            </div>
        );

        return (
            <>
                <div key={currentBuilding.id} className="abs top-left flex handles">
                    <div className="panel-label handle">
                        <div className="text">
                            {currentBuilding.description} - {getAddressStringFromObject(currentBuilding)}
                        </div>
                    </div>
                </div>
                <If condition={!campusView}>
                    <div className="panel-area">
                        <div className="grid-layout details-panel">{this.renderBlocks()}</div>
                    </div>
                </If>
                {mapDisplay}
            </>
        );
    }
}
